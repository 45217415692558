import WrapperSign from "./WrapperSignContract.vue";

export default {
  routes: [
    {
      path: "",
      component: WrapperSign,
      children: [
        {
          path: "/sign-nic",
          name: "sign-nic",
          component: () => import("./NicSing.vue"),
        },
        {
          path: "/sign-sms",
          name: "sign-sms",
          component: () =>
            import("@/views/SignContract/SmsSignature/Index.vue"),
        },
        {
          path: "/c/:id",
          name: "c",
          component: () => import("./CRedirect.vue"),
        },
        {
          path: "/sing-contract/:id/:contractId",
          name: "sing-contract",
          component: () => import("./PreviewContract.vue"),
        },
        {
          path: "/sign-contract/signature-success",
          name: "signature-success",
          component: () => import("./SignatureSuccess.vue"),
        },
        // {
        //   path: "/sign-contract/signature-success",
        //   name: "signature-success-page",
        //   component: () => import("./SuccessPage.vue"),
        // },
      ],
    },
  ],
};
