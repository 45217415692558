import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import router from "@/router";
import Auth from "@/service/auth/auth.service";

const toast = useToast();

export const useAuthStore = defineStore("auth", {
  state: () => ({
    records: {},
    isLoading: false,
    loginErrMsg: null,
  }),
  getters: {},
  actions: {
    async signUp(user) {
      try {
        this.isLoading = true;
        const { data } = await Auth.register(user);
        $cookies.set(
          "otp",
          { email: user.email, uuid: data.data.uuid },
          "1m",
          "", // Path
          "", // Domain
          true, // Secure flag
          true // Secure flag
        );
        toast.success(data.data.message);
      } catch (error) {
        toast.error(error.response.data.message);
        return error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
    async checkOTP(otp) {
      try {
        this.isLoading = true;
        const otpData = $cookies.get("otp");
        const { data } = await Auth.checkOtp({
          ...otpData,
          ...otp,
        });
        $cookies.remove("otp");
        this.records = data.data;
        $cookies.set("token_wtheq_app", data.data.token, "1y");
        router.push("/settings-company-data");
        window.location.reload();
      } catch (error) {
        toast.error(error.response.data.message);
        return error.response;
      } finally {
        this.isLoading = false;
      }
    },
    async resendOTP() {
      try {
        this.isLoading = true;
        const otpData = $cookies.get("otp");
        const { data } = await Auth.resendOtp({ ...otpData });
        $cookies.set(
          "otp",
          { email: otpData.email, uuid: data.data.uuid },
          "1m"
        );
        toast.success(data.data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async signIn(user) {
      this.clearLoginErrMsg();
      try {
        this.isLoading = true;
        const { data }  = await Auth.login(user);
        if (data.data.uuid) {
          $cookies.set(
            "otp",
            { email: user.email, uuid: data.data.uuid },
            "1m",
            "", // Path
            "", // Domain
            true, // Secure flag
            true // Secure flag
          );
          toast.success(data.data.message);
          await router.push("/register/otp");
        } else {
          this.records = data.data;
          $cookies.remove("email_wtheq_app");
          $cookies.remove("otp");
          if (user.rememberMe) {
            $cookies.set(
              "token_wtheq_app", // Cookie name
              data.data.token, // Cookie value
              "1m", // Expiry time (1 minute in this case)
              "/", // Path (Root path is common)
              "", // Domain (Optional, can be left empty)
              true, // Secure flag (Only sent over HTTPS)
              true // HttpOnly flag (Not accessible via JavaScript)
            );
          } else {
            $cookies.set(
              "token_wtheq_app", // Cookie name
              data.data.token, // Cookie value
              "1m", // Expiry time (1 minute in this case)
              "/", // Path (Root path is common)
              "", // Domain (Optional, can be left empty)
              true, // Secure flag (Only sent over HTTPS)
              true // HttpOnly flag (Not accessible via JavaScript)
            );
          }

          if (data.data.create_contracts) {
            await router.push("/dashboard");
          } else {
            await router.push("/settings-company-data");
          }
        }
      } catch (error) {
        this.loginErrMsg = error.response.data.message;
        // toast.error(error.response.data.message);
        return error.response;
      } finally {
        this.isLoading = false;
      }
    },

    clearLoginErrMsg() {
      this.loginErrMsg = null;
    },

    async resetPasswordByLink(user) {
      try {
        this.isLoading = true;
        const { data } = await Auth.resetPasswordByLink(user);
        toast.success(data.message);
        $cookies.set("email_wtheq_app", user, "1m");
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    async resetPassword(user) {
      try {
        this.isLoading = true;
        const { data } = await Auth.resetPassword(user);
        toast.success(data.message);
        router.push("/");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    async logout() {
      try {
        this.isLoading = true;
        await Auth.logout();
        $cookies.remove("token_wtheq_app");
        this.records = {};
        router.push("/");
        window.location.reload();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    async cRedirection(id) {
      try {
        this.isLoading = true;
        const { data } = await Auth.cRedirection(id);
        if (data.url) {
          window.location.href = data.url;
        }
      } catch (error) {
        toast.error(error.response.data.error);
        router.push("/404");
      } finally {
        this.isLoading = false;
      }
    },

    async newEmployeePassword(user) {
      try {
        this.isLoading = true;
        const { data } = await Auth.newEmployeePassword(user);
        // toast.success(data.message);
        router.push("/");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async checkUserExists(id) {
      try {
        this.isLoading = true;
        const { data } = await Auth.checkUser(id);
        return true;
        // toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async checkValidUserToken(id) {
      try {
        this.isLoading = true;
        const { data } = await Auth.checkValidToken(id);
        return true;
        // toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
