import ApiClient from "@/helper/api.helper";
class Plans extends ApiClient {
	constructor() {
		super("/api/plans");
	}

	contactUs() {
		return axios.patch(`${this.url}/message`);
	}
}

export default new Plans();
