import { defineStore } from "pinia";
import Plans from "@/service/plans/plans.service";
import { useToast } from "vue-toastification";
const toast = useToast();
export const usePlansStore = defineStore("Plans", {
  state: () => ({
    records: [],
    isLoading: false,
    meta: {},
  }),
  getters: {},
  actions: {
    getPlans: async function (params) {
      try {
        this.isLoading = true;
        const { data } = await Plans.get(params);
        this.records = data.data;
        this.meta = data.meta;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    contactUs: async function () {
      try {
        const { data } = await Plans.contactUs();
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  },
});
