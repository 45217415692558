import ApiClient from "@/helper/api.helper";
class Payment extends ApiClient {
  constructor() {
    super("/api/payments");
  }

  create_callback(data) {
    return axios.post(`${this.url}/callback`, data);
  }

  getSubscriptionData() {
    return axios.get(`/api/plans/get/getMyCurrentSubscription`);
  }

  invoice(data) {
    return axios.post(`${this.url}/invoice`, data);
  }

  payNow() {
    return axios.post(`/api/company/paySubscription`);
  }

  subscriptionPaymentCallback(data) {
    return axios.post(`/api/subscription/payments/callback`, data);
  }

  viewInvoices(id) {
    return axios.get(`/api/invoices/view/${id}`);
  }

  getPeriods() {
    return axios.get(`/api/plans/list/listTimePeriods`);
  }

  getPlans(period) {
    return axios.get(
      `/api/plans/get/getPlansForCompany?filters[time_period_id]=${period}`
    );
  }

  subscripe(body) {
    return axios.post(`/api/company/subscribe`, body);
  }

  customPlan(body) {
    return axios.patch(`/api/plans/message`, body);
  }
}

export default new Payment();
