import { roleGuard } from "@/helper/router.helper";
import WrapperUsers from "./WrapperUsers.vue";

export default {
  routes: [
    {
      path: "users",
      component: WrapperUsers,
      redirect: { name: "users-list" },
      children: [
        {
          component: () => import("./UsersList.vue"),
          path: "",
          name: "users-list",
          meta: {
            title: "SIDEBAR.EMPLOYEES",
            pageType: "SIDEBAR.EMPLOYEES",
            roles: ["admin"],
          },
          beforeEnter: roleGuard,
        },
        {
          path: "/users/create",
          name: "create-user",
          component: () => import("./CreateUser.vue"),
          meta: {
            title: "SIDEBAR.EMPLOYEES",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin"],
          },
          beforeEnter: roleGuard,
        },
        {
          path: "/users/edit/:id",
          name: "edit-user",
          component: () => import("./EditUser.vue"),
          meta: {
            title: "SIDEBAR.EMPLOYEES",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin"],
          },
          beforeEnter: roleGuard,
        },
      ],
    },
  ],
};
