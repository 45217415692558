import ApiClient from "@/helper/api.helper";

class Auth extends ApiClient {
  constructor() {
    super("/api");
  }

  login(user) {
    return axios.post(
      `${this.url}/login`,
      {
        ...user,
      },
      {withCredentials: true}
    );
  }

  register(user) {
    return axios.post(`${this.url}/register`, {
      ...user,
    });
  }

  checkOtp(user) {
    return axios.post(`${this.url}/check-otp`, {
      ...user,
    });
  }

  resendOtp(user) {
    return axios.post(`${this.url}/resend-otp`, {
      ...user,
    });
  }

  resetPasswordByLink(user) {
    return axios.post(`${this.url}/password/email`, {
      ...user,
    });
  }

  resetPassword(user) {
    return axios.post(`${this.url}/password/reset`, {
      ...user,
    });
  }

  logout() {
    return axios.post(`${this.url}/logout`);
  }

  cRedirection(id) {
    return axios.get(`${this.url}/c/${id}`);
  }

  checkUser(id) {
    return axios.get(`${this.url}/new-employee-password-check/${id}`);
  }

  newEmployeePassword(data) {
    return axios.post(`${this.url}/new-employee-password`, {
      ...data,
    });
  }

  checkValidToken(data) {
    return axios.post(`${this.url}/password/validate`, {
      ...data,
    });
  }
}

export default new Auth();
