import total from "/assets/icons/total.svg"
import completed from "/assets/icons/completed.svg"
import sent from "/assets/icons/sent.svg"
import in_review from "/assets/icons/in_review.svg"
import delete_icon from "/assets/icons/delete_icon.svg"
import edit_icon from "/assets/icons/edit_icon.svg"
import view_icon from "/assets/icons/view_icon.svg"
import accept_icon from "/assets/icons/accept_icon.svg"
import cancel_icon from "/assets/icons/cancel_icon.svg"
import create from "/assets/icons/create.svg"
import status from "/assets/icons/status.svg"
import message from "/assets/icons/message.svg"
import contract from "/assets/icons/contract.svg"


import address from "/assets/icons/company/address.svg"
import city from "/assets/icons/company/city.svg"
import email from "/assets/icons/company/email.svg"
import location from "/assets/icons/company/location.svg"
import name from "/assets/icons/company/name.svg"
import phone from "/assets/icons/company/phone.svg"
import postalcode from "/assets/icons/company/postalcode.svg"
import register from "/assets/icons/company/register.svg"
import register_2 from "/assets/icons/company/register_2.svg"
import type from "/assets/icons/company/type.svg"


export default {
    total,
    completed,
    sent,
    in_review,
    delete_icon,
    view_icon,
    edit_icon,
    accept_icon,
    cancel_icon,
    create,
    status,
    message,
    contract,
    address,
    city,
    email,
    name,
    location,
    phone,
    postalcode,
    register_2,
    register,
    type
}
