<template>
  <div id="aside-bar" v-if="account?.features">
    <div class="aside d-flex flex-column justify-space-between">
      <!-- Start Links -->
      <div class="">
        <div class="d-flex ga-4 align-center pa-4 border-b">
          <button @click="$emit('toggleAside')" class="d-md-none">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="4" fill="#F9FAFB" />
              <path
                d="M11 20H29M11 14H29M11 26H23"
                stroke="#858585"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <img
            v-if="showAside"
            src="/assets/images/logo.svg"
            alt="logo"
            class="aside__logo"
          />
          <img v-else src="/assets/images/logoText.svg" alt="logo" />
        </div>
        <v-list class="px-3">
          <v-list-item
            v-for="link in listLinks"
            :key="link.to"
            :to="link.to"
            link
            active-class="router-link-active"
            class="w-100 ga-4 align-center aside__link justify-space-between pa-0"
            :class="{
              'd-flex':
                !link.check ||
                (link.check && account?.features[link.check] != false),
              'd-none': link.check && account?.features[link.check] == false,
            }"
          >
            <div class="d-flex ga-4 align-center">
              <img
                :src="link.icon"
                :alt="link.label"
                class="aside__link__icon ms-4"
              />
              <span class="block w-full aside__link__text">
                {{ link.label }}
              </span>
            </div>
            <!-- <span
                v-if="!isLoading && link.hasNumber"
                class="circle-animate circle-animate--width"
              >
                {{ link.number }}
              </span> -->
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
import { useUserProfileStore } from "@/stores/auth/user.store";
import { mapState } from "pinia";
import { useCompanyStore } from "@/stores/companies/profile/profile.store";

export default {
  props: {
    showAside: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSetting: false,
    };
  },
  mounted() {
    if (this.$route.meta?.wide) {
      this.$emit("closeAsideViaLink");
    }
  },
  watch: {
    $route() {
      if (this.$route.meta?.wide) {
        this.$emit("closeAsideViaLink");
      }
    },
  },
  computed: {
    ...mapState(useUserProfileStore, ["account"]),
    ...mapState(useCompanyStore, ["record", "isLoading"]),
    companyStatus() {
      return this.record?.status !== "approved";
    },
    planStatus() {
      return !this.record?.has_plan;
    },

    accountStatus() {
      return !this.account?.is_verified;
    },
    listLinks() {
      let pages = [
        {
          label: this.$t("SIDEBAR.HOME"),
          icon: "/assets/images/aside/side_home_icon.svg",
          to: "/dashboard",
          role: ["admin"],
          hasNumber: false,
        },
        {
          label: this.$t("SIDEBAR.CONTRACTS"),
          icon: "/assets/images/aside/document.svg",
          to: "/contracts",
          role: ["admin", "template_moderator", "authorized_employee"],
          hasNumber: false,
        },
        // {
        //   label: this.$t("CONTRACTS_APPROVES.TITLE"),
        //   icon: "/assets/images/aside/document.svg",
        //   to: "/contracts-approves",
        //   role: ["admin", "template_moderator", "authorized_employee"],
        //   hasNumber: false,
        // },
        // {
        //   label: this.$t("CONTRACTS_SIGN.TITLE"),
        //   icon: "/assets/images/aside/document.svg",
        //   to: "/contracts-sign",
        //   role: ["admin", "template_moderator", "authorized_employee"],
        //   hasNumber: false,
        // },
        {
          label: this.$t("SIDEBAR.TEMPLATES"),
          icon: "/assets/images/aside/side_template_icon.svg",
          to: "/template",
          role: ["admin", "template_moderator", "authorized_employee"],
          hasNumber: false,
          check: "templates",
        },
        {
          label: this.$t("SIDEBAR.EMPLOYEES"),
          icon: "/assets/images/aside/users.svg",
          to: "/users",
          role: ["admin"],
          hasNumber: this.accountStatus,
          number: 1,
        },
        {
          label: this.$t("SIDEBAR.COMPANY_DATA"),
          icon: "/assets/images/aside/company.svg",
          to: "/settings-company-data",
          role: ["admin"],
          hasNumber: this.companyStatus,
          number: 2,
        },
        {
          label: this.$t("SIDEBAR.PAYMENTS"),
          to: "/subscription",
          role: ["admin"],
          icon: "/assets/images/aside/payment.svg",
          hasNumber: this.planStatus,
          number: 3,
        },
      ];
      if (this.account?.role?.value !== "admin") {
        pages = pages.filter((page) => {
          return page.role.includes(this.account?.role.value);
        });
      }

      // pages = pages.filter((page) => {
      //   return !page.check || (page.check && this.account.features[page.check]);
      // });

      return pages;
    },
  },
};
</script>
<style lang="scss" scoped>
.aside {
  border: none !important;
  background: $global-white;
  position: sticky;
  border-radius: var(--Lg, 16px);
  top: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  min-height: calc(100vh - 48px);
  margin: 24px 0;

  @media (max-width: 992px) {
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  &__link {
    color: $global-400;
    font-size: $xl;

    &__icon {
      filter: grayscale(1);
      width: 20px;
      height: 20px;
    }

    &.router-link-active,
    &.router-link-exact-active {
      color: $main-color;
      border-radius: $lg;
      background-color: #f8fafb;
    }
  }

  .v-list-item--link.router-link-active {
    .aside__link__icon {
      filter: none;
    }
  }

  &__logo {
    width: 88.866px;
    height: 35.057px;
  }
}
</style>
