import ApiClient from "@/helper/api.helper";

class Authorized extends ApiClient {
  constructor() {
    super("/api/authorized-employees");
  }

  get(params) {
    return axios.get(this.url, {
      params: {
        ...params
      }
    });
  }

  check_nic_authorized(user) {
    return axios.patch(`/api/authorized-employee/nafath/send/${user}`);
  }

  send_nic_authorized(data) {
    return axios.post(`/api/authorized-employee/nafath/authorize`, data);
  }
}

export default new Authorized();
