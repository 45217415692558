<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.965116"
      y="0.465116"
      width="39.0698"
      height="39.0698"
      rx="7.53488"
      fill="#F3FAF8"
    />
    <rect
      x="0.965116"
      y="0.465116"
      width="39.0698"
      height="39.0698"
      rx="7.53488"
      stroke="#51A79A"
      stroke-width="0.930233"
    />
    <path
      d="M20.5018 20.7736C20.9299 20.7736 21.2769 20.4266 21.2769 19.9984C21.2769 19.5703 20.9299 19.2232 20.5018 19.2232C20.0736 19.2232 19.7266 19.5703 19.7266 19.9984C19.7266 20.4266 20.0736 20.7736 20.5018 20.7736Z"
      stroke="#51A79A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5018 15.3473C20.9299 15.3473 21.2769 15.0002 21.2769 14.5721C21.2769 14.1439 20.9299 13.7969 20.5018 13.7969C20.0736 13.7969 19.7266 14.1439 19.7266 14.5721C19.7266 15.0002 20.0736 15.3473 20.5018 15.3473Z"
      stroke="#51A79A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5018 26.2C20.9299 26.2 21.2769 25.8529 21.2769 25.4248C21.2769 24.9967 20.9299 24.6496 20.5018 24.6496C20.0736 24.6496 19.7266 24.9967 19.7266 25.4248C19.7266 25.8529 20.0736 26.2 20.5018 26.2Z"
      stroke="#51A79A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style lang=""></style>
