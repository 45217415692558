<template>
  <div>
    <div class="header d-flex align-end">
      <v-container>
        <img src="/assets/images/logo-white.svg" alt="logo" />
        <div
          v-if="contract !== null"
          class="d-flex align-center ga-1 text-white"
        >
          <span>توقيع عقد</span>
          <span>
            {{ contract.title }}
          </span>
        </div>
      </v-container>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useSecondSideStore } from "@/stores/secondSide/secondSide.store";
export default {
  computed: {
    ...mapState(useSecondSideStore, ["contract", "isLoading"])
  }
};
</script>
<style lang="scss" scoped>
.header {
  min-height: 120px;
  background-image: url("/assets/images/secondSide/header.png");
  background-size: cover;
  background-position: center center;
}
</style>
