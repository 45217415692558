import ApiClient from "@/helper/api.helper";

class Notifications extends ApiClient {
	constructor() {
		super("/api/notifications");
	}

	readAll() {
		return axios.get(`${this.url}/read`);
	}
}

export default new Notifications();
