import { roleGuard } from "@/helper/router.helper";
import authRoutes from "@/views/Auth/auth.routes";
import companiesRoutes from "@/views/Companies/companies.routes";
import secondSideRoutes from "@/views/SecondSide/secondSide.routes";

import NICAuthorized from "@/views/NICAuthorized/NICAuthorized.routes";

import { createRouter, createWebHistory } from "vue-router";
import signContractRoutes from "@/views/SignContract/sign.contract.routes";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/subscription/e-invoice/:id",
      name: "e-invoice",
      component: () =>
        import("../views/Companies/Settings/payment/EInvoice.vue"),
      meta: {
        title: "SIDEBAR.PAYMENTS",
        roles: ["admin"],
      },
      beforeEnter: roleGuard,
    },
    ...authRoutes.routes,
    ...companiesRoutes.routes,
    ...secondSideRoutes.routes,
    ...NICAuthorized.routes,

    ...signContractRoutes.routes,
    {
      path: "/owner-check",
      name: "owner-check",
      component: () => import("@/views/Companies/Settings/OwnerCheck.vue"),
      meta: {
        roles: ["admin"],
      },
      beforeEnter: roleGuard,
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/NotFound.vue"),
    },
    {
      path: "/conditions",
      name: "conditions",
      component: () => import("@/views/Conditions.vue"),
    },
    {
      path: "/wtheq/conditions",
      name: "wtheq-conditions",
      component: () => import("@/views/Conditions.vue"),
    },
    {
      path: "/contracts-iframe/:id/:mode",
      name: "contract-iframe",
      component: () =>
        import("@/views/Companies/Contracts/EditContract/ContractIframe.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS_DETAILS",
      },
    },

    // {
    //   path: "/verify-signature-sms/:id",
    //   name: "verify-signature-sms",
    //   component: () => import("@/views/Companies/SmsSignature/Index.vue"),
    //   meta: {
    //     title: "SIDEBAR.CONTRACTS_DETAILS",
    //   },
    // },
    // {
    //   path: "/verify-signature-nafath/:id",
    //   name: "verify-signature-nafath",
    //   component: () => import("@/views/NafathSignature/Index.vue"),
    //   meta: {
    //     title: "SIDEBAR.CONTRACTS_DETAILS",
    //   },
    // },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
