import { defineStore } from "pinia";
import Company from "@/service/companies/profile/profile.service";
import { useToast } from "vue-toastification";
const toast = useToast();

export const useCompanyStore = defineStore("company", {
  state: () => ({
    record: {},
    isLoading: false,
    isUpdating: false,
  }),

  getters: {},

  actions: {
    get: async function () {
      try {
        this.isLoading = true;
        const { data } = await Company.get();
        this.record = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    updateOwner: async function (params) {
      try {
        this.isLoading = true;
        const { data } = await Company.update_owner(params);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    updateCompany: async function (params, hasSubscription) {
      try {
        const formData = new FormData();
        for (const val of Object.entries(params)) {
          formData.append(val[0], val[1]);
        }
        const { data } = await Company.update_company(formData);
        if (hasSubscription) {
          toast.success(data.message);
        }
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      }
    },

    uploadFiles: async function (params) {
      try {
        this.isUpdating = true;
        const { data } = await Company.upload_files(params);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isUpdating = false;
      }
    },
  },
});
