import { authGuard } from "@/helper/router.helper";
import AuthView from "./Auth.vue";

export default {
  routes: [
    {
      component: AuthView,
      redirect: { name: "sign-in" },
      path: "",
      beforeEnter: authGuard,
      children: [
        {
          path: "/",
          name: "sign-in",
          component: () => import("./SingIn.vue"),
        },
        {
          path: "/forget-password",
          name: "forget-password",
          component: () => import("./ForgetPassword.vue"),
        },
        {
          path: "/check-email",
          name: "check-email",
          component: () => import("./CheckEmail.vue"),
        },
        {
          path: "/sign-up",
          name: "sign-up",
          component: () => import("./SingUp.vue"),
        },
      ],
    },

    {
      path: "/new-employee/:id/create-password",
      name: "create-password",
      component: () => import("./CreatePassword.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS_DETAILS",
      },
    },

    {
      path: "/otp",
      name: "otp",
      component: () => import("./OTP.vue"),
    },
    {
      path: "/check-email",
      name: "check-email",
      component: () => import("./CheckEmail.vue"),
    },
    {
      path: "/register/otp",
      name: "registerOtp",
      component: () => import("./RegisterOtp.vue"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./ResetPassword.vue"),
    },
  ],
};
