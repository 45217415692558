import ApiClient from "@/helper/api.helper";

class Workflow extends ApiClient {
	constructor() {
		super("/api/workflows");
	}

	get(params) {
		return axios.get(this.url, {
			params: {
				...params,
			},
		});
	}
}

export default new Workflow();
