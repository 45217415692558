<template>
  <div class="auth">
    <v-row class="ga-md-0 mx-4 mx-md-0">
      <v-col md="6" cols="12" class="mb-md-0 pa-md-0 auth-form">
        <div class="content-page justify-md-space-between">
          <div
            class="pa-8 pb-md-0 px-0 px-md-8 d-flex align-center justify-space-between"
          >
            <img src="/assets/images/logo.svg" alt="logo" width="128" />
            <wb-button
              id="change_lang_activator"
              type="text"
              @click="changeLang"
              style="width: fit-content !important"
            >
              <template #content>
                <div class="d-flex">
                  <svg
                    class="me-2"
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 11.3359H21M1 11.3359C1 16.8588 5.47715 21.3359 11 21.3359M1 11.3359C1 5.81309 5.47715 1.33594 11 1.33594M21 11.3359C21 16.8588 16.5228 21.3359 11 21.3359M21 11.3359C21 5.81309 16.5228 1.33594 11 1.33594M11 1.33594C13.5013 4.07429 14.9228 7.62797 15 11.3359C14.9228 15.0439 13.5013 18.5976 11 21.3359M11 1.33594C8.49872 4.07429 7.07725 7.62797 7 11.3359C7.07725 15.0439 8.49872 18.5976 11 21.3359"
                      stroke="#5C5C5C"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span class="text-dark-700">{{
                    $i18n.locale == "ar" ? "English" : "العربية"
                  }}</span>
                </div>
              </template>
            </wb-button>
          </div>
          <div class="py-12 bg-white w-100">
            <router-view v-slot="{ Component, route }">
              <transition name="fade">
                <component :is="Component" :key="route.path" />
              </transition>
            </router-view>
          </div>
          <div class="copy-right">
            <p
              class="text-center d-flex align-center ga-1 justify-center dark-700"
            >
              {{ $t("COPY_RIGHT") }}
              &copy; {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col md="6" cols="12" class="pa-0 d-none d-md-block">
        <div class="position-relative">
          <div
            :class="{ en: $i18n.locale == 'en' }"
            class="bg-section"
            :style="$i18n.locale == 'en' ? 'left: unset; right: 0' : ''"
          ></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    changeLang() {
      localStorage.setItem(
        "localeLang",
        this.$i18n.locale == "ar" ? "en" : "ar"
      );
      location.reload();
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
.bg-section {
  background: url("/assets/images/auth/auth_cover.png") no-repeat center center;
  background-size: cover;
  background-position: center center;
  min-height: 100dvh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  &.en {
    background: url("/assets/images/auth/english-pg.png") no-repeat center
      center;
  }
}

.content-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  @media (max-width: 992px) {
    padding: 24px 0;
    min-height: auto;
  }
}

.auth {
  background-color: #fff;
  min-height: 100dvh;
  &_form {
    height: 100dvh;
  }
}

.copy-right {
  border-top: 1px solid var(--02---Dark-Dark---100, #f5f5f5);
  background: #fff;
  padding: var(--3XL, 24px) 0px;

  p {
    color: var(--02---Dark-Dark---500, #858585);

    /* Body/Regular - 12px */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  @media (max-width: 992px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
  }
}
.form-col {
  height: calc(100% - 85px);
}

@media (max-width: 960px) {
  .auth-form {
    min-height: 100dvh !important;
  }
}
</style>
