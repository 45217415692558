import { roleGuard } from "@/helper/router.helper";

export default {
  routes: [
    {
      path: "information",
      name: "template-create-information",
      component: () => import("./TemplateInformation.vue"),
      meta: {
        title: "SIDEBAR.TEMPLATES",
        pageType: "CONTRACT.CREATE",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 1,
      },
      // beforeEnter: roleGuard,
    },
    {
      path: "parties/:id",
      name: "template-create-parties",
      component: () => import("./TemplateParties.vue"),
      meta: {
        title: "SIDEBAR.TEMPLATES",
        pageType: "CONTRACT.CREATE",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 2,
      },
      // beforeEnter: roleGuard,
    },
  ],
};
