import ApiClient from "@/helper/api.helper";

class Contract extends ApiClient {
  constructor() {
    super("/api/contracts");
  }

  get(params, filter) {
    const url = filter ? `${this.url}?${filter}` : this.url;
    return axios.get(url, {
      params: {
        ...params,
      },
    });
  }

  createStepOne(data) {
    return axios.post(`${this.url}/create/step/one`, data);
  }

  createStepTwo(data) {
    return axios.post(`${this.url}/create/step/two`, data);
  }

  create(item) {
    return axios.post(`${this.url}/create`, item);
  }

  update(data, id) {
    return axios.post(`${this.url}/${id}/update`, data);
  }

  get_status() {
    return axios.get(`${this.url}/statuses/list`);
  }

  create_places(id, item) {
    return axios.post(`${this.url}/${id}/places`, item);
  }

  send_contract(id) {
    return axios.post(`${this.url}/${id}/send`);
  }

  approve_contract(id) {
    return axios.patch(`${this.url}/${id}/approve`);
  }

  get_contract_history(id) {
    return axios.get(`${this.url}/${id}/history`);
  }

  get_all_comments(id) {
    return axios.get(`${this.url}/${id}/comments`);
  }

  send_comment(id, data) {
    return axios.post(`${this.url}/${id}/comments`, data);
  }

  update_contract_data(id, data) {
    return axios.post(`${this.url}/${id}/update`, data);
  }

  update_contract_file(id, data) {
    return axios.post(`${this.url}/${id}/update-file`, data);
  }

  clone_contract(id) {
    return axios.patch(`${this.url}/${id}/clone`);
  }

  close_contract(id) {
    return axios.patch(`${this.url}/${id}/cancel`);
  }

  sendSmsOtp(id) {
    return axios.patch(`/api/second-side/send-otp/${id}`);
  }

  deleteContract(id) {
    return axios.delete(`${this.url}/${id}/delete`);
  }

  getPendingSign(params) {
    return axios.get(`${this.url}/pending-on-me/list`, {
      params: {
        ...params,
      },
    });
  }

  verifySmsOtp(otp) {
    return axios.patch(`/api/second-side/verify-otp/${otp.token}`, {
      otp_code: otp.otp_code,
    });
  }

  getUserInfo(token) {
    return axios.get(`/api/second-side/info/${token}`);
  }
}

export default new Contract();
