import ApiClient from "@/helper/api.helper";

class Company extends ApiClient {
  constructor() {
    super("/api/company");
  }

  update_owner(data) {
    return axios.post(`${this.url}/update-owner`, data);
  }

  update_company(data) {
    return axios.post(`${this.url}/update`, data);
  }

  upload_files(data) {
    return axios.post(`${this.url}/upload-attachment`, data);
  }
}

export default new Company();
