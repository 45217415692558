<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.66406 3.83333C1.66406 3.36662 1.66406 3.13327 1.75489 2.95501C1.83478 2.79821 1.96227 2.67072 2.11907 2.59083C2.29733 2.5 2.53069 2.5 2.9974 2.5H16.9974C17.4641 2.5 17.6975 2.5 17.8757 2.59083C18.0325 2.67072 18.16 2.79821 18.2399 2.95501C18.3307 3.13327 18.3307 3.36662 18.3307 3.83333V4.39116C18.3307 4.61516 18.3307 4.72716 18.3034 4.8313C18.2791 4.92359 18.2392 5.01103 18.1853 5.0898C18.1245 5.17869 18.0399 5.25204 17.8706 5.39875L12.5408 10.0179C12.3715 10.1646 12.2869 10.238 12.2261 10.3269C12.1723 10.4056 12.1324 10.4931 12.1081 10.5854C12.0807 10.6895 12.0807 10.8015 12.0807 11.0255V15.382C12.0807 15.5449 12.0807 15.6264 12.0544 15.6969C12.0312 15.7591 11.9934 15.8149 11.9443 15.8596C11.8886 15.9102 11.813 15.9404 11.6617 16.001L8.82832 17.1343C8.52204 17.2568 8.36889 17.3181 8.24596 17.2925C8.13845 17.2702 8.04411 17.2063 7.98344 17.1148C7.91406 17.0101 7.91406 16.8452 7.91406 16.5153V11.0255C7.91406 10.8015 7.91406 10.6895 7.88669 10.5854C7.86243 10.4931 7.82251 10.4056 7.76865 10.3269C7.70788 10.238 7.62324 10.1646 7.45397 10.0179L2.12416 5.39875C1.95488 5.25204 1.87025 5.17869 1.80947 5.0898C1.75562 5.01103 1.71569 4.92359 1.69143 4.8313C1.66406 4.72716 1.66406 4.61516 1.66406 4.39116V3.83333Z"
      stroke="#51A79A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {};
</script>
