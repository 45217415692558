import { roleGuard } from "@/helper/router.helper";
import WrapperContracts from "./WrapperContracts.vue";
import ContractsList from "./ContractsList.vue";

export default {
  routes: [
    {
      path: "contracts-sign",
      component: WrapperContracts,
      redirect: { name: "contracts-sign-list" },
      children: [
        {
          component: ContractsList,
          path: "/contracts-sign",
          name: "contracts-sign-list",
          meta: {
            title: "CONTRACTS_SIGN.TITLE",
            pageType: "SIDEBAR.CONTRACTS",
            roles: ["admin", "template_moderator", "authorized_employee"],
          },
          beforeEnter: roleGuard,
        },
        {
          component: () => import("./ContractPreview.vue"),
          path: "/contracts-sign/preview/:id",
          name: "contract-sign-view",
          meta: {
            title: "SIDEBAR.CONTRACTS",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin", "template_moderator", "authorized_employee"],
          },
          beforeEnter: roleGuard,
        },
      ],
    },
  ],
};
