// Plugins
import vuetify from "./vuetify";
import { i18n } from "./I18n";
import pinia from "@/stores";
import router from "@/router";
import VueCookies from "vue-cookies";
import Toast, { POSITION } from "vue-toastification";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-tel-input/dist/vue3-tel-input.css";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import echo from "./pusher";

import Vue3Signature from "vue3-signature";

// Register local components
import initApp from "./defineComponents";

export function registerPlugins(app) {
  const lang = localStorage.getItem("localeLang");
  app
    .use(i18n)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(VueCookies)
    .use(Toast, {
      position: lang == "ar" ? POSITION.BOTTOM_LEFT : POSITION.BOTTOM_RIGHT,
    })
    .use(Vue3Signature);
  app.component("VueDatePicker", VueDatePicker);

  initApp.install(app);
  $cookies.config("1y", "", "", true);

  app.config.globalProperties.$echo = echo;
}
