import { defineStore } from "pinia";
import Authorized from "@/service/companies/authorized/authorized.service";
import { useToast } from "vue-toastification";
const toast = useToast();
export const useAuthorizedStore = defineStore("authorized", {
	state: () => ({
		records: [],
		isLoading: false,
		meta: {},
	}),

	getters: {},

	actions: {
		get: async function (params) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.get(params);
				this.records = data.data;
				this.meta = data.meta;
			} catch (error) {
			} finally {
				this.isLoading = false;
			}
		},
		showAuthorized: async function (id) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.show(id);
				return data.data;
			} catch (error) {
				return {};
			} finally {
				this.isLoading = false;
			}
		},
		create: async function (obj) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.create(obj);
				if (this.records.length < this.meta.per_page) {
					this.records.push(data.data);
				}
				toast.success(data.message);
				return true;
			} catch (error) {
				toast.error(error.response.data.message);
				return false;
			} finally {
				this.isLoading = false;
			}
		},
		update: async function (id, obj) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.update(id, obj);
				const findItem = this.records.find((item) => item.id === id);
				Object.assign(findItem, data.data);
				toast.success(data.message);
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		delete: async function (id) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.delete(id);
				this.records = this.records.filter((record) => record.id !== id);
				toast.success(data.message);
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},

		check_nic_authorized: async function (user) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.check_nic_authorized(user);
				toast.success(data.message);
				return data;
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},

		send_nic_authorized: async function (obj) {
			try {
				this.isLoading = true;
				const { data } = await Authorized.send_nic_authorized(obj);
				toast.success(data.message);
				return data;
			} catch (error) {
				toast.error(error.response.data.message);
				return false;
			} finally {
				this.isLoading = false;
			}
		},
	},
});
