<template>
  <div class="setting px-lg-8 pt-0 px-0">
    <!-- <div class="setting__header">
      <div class="setting__header__content d-flex ga-3 align-center">
        <div class="setting__logo">
          <label class="setting__logo__btn">
            <img src="/assets/images/settings/add-image.svg" alt="add-image" />

            <input type="file" class="hidden d-none" @input="updateAvatar" />
          </label>
          <template v-if="isUpload">
            <v-progress-circular color="primary" indeterminate />
          </template>
          <img
            v-else-if="record?.logo == null"
            class="setting__logo__icon"
            src="/assets/images/auth/corporate.svg"
            alt="corporate"
          />
          <img
            v-else
            class="setting__logo__img"
            :src="record?.logo"
            alt="corporate"
          />
        </div>
        <div>
          <h1 class="setting__header__title">
            {{ record.name }}
          </h1>
          <p class="setting__header__text">
            {{ record.commercial_register_number }}
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="aside__list d-flex align-center ga-4 mb-4">
			<router-link
				v-for="(link, index) in settingPages"
				:key="index"
				:to="link.to"
				class="aside__item"
			>
				<span>{{ link.label }}</span>
			</router-link>
		</div> -->
    <router-view></router-view>
  </div>
</template>
<script>
import { useCompanyStore } from "@/stores/companies/profile/profile.store";
import { mapActions, mapState } from "pinia";
import { useUserProfileStore } from "@/stores/auth/user.store";

export default {
  data() {
    return {
      settingPages: [
        {
          label: this.$t("SIDEBAR.COMPANY_DATA"),
          to: "/settings-company-data",
        },
        {
          label: this.$t("SIDEBAR.DELEGATES_DATA"),
          to: "/settings-delegates-data",
        },
        {
          label: this.$t("SIDEBAR.PAYMENTS"),
          to: "/payments-plans",
        },
      ],
    };
  },

  computed: {
    ...mapState(useCompanyStore, ["record"]),
    ...mapState(useUserProfileStore, ["isUpload"]),
  },
  methods: {
    ...mapActions(useCompanyStore, ["get"]),
    ...mapActions(useUserProfileStore, ["uploadAvatar"]),

    async updateAvatar(e) {
      const file = e.target.files[0];
      this.record.logo = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append("avatar", file);
      formData.append("type", "company");
      await this.uploadAvatar(formData);
    },
  },
};
</script>
<style lang="scss" scoped>
.setting {
  padding: 20px;
  &__header {
    background: rgba(248, 250, 251, 0.36);
    border-radius: var(--XL, 16px);
    height: 170px;
    background-image: url("/assets/images/settings/bg-setting.svg");
    background-repeat: no-repeat;
    background-position: 10% center;
    @media (max-width: 992px) {
      height: 85px;
    }
  }
  &__logo {
    width: 142px;
    height: 142px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px var(--4Xl, 32px) -4px rgba(5, 5, 5, 0.06);
    border: 3px solid var(--Primary-50, #fafafa);
    background: #fff;
    border-radius: 50%;
    position: relative;
    @media (max-width: 992px) {
      width: 71px;
      height: 71px;
    }
    &__icon {
      width: 80px;
      height: 80px;
      @media (max-width: 992px) {
        width: 40px;
        height: 40px;
      }
    }
    &__img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: contain;
      @media (max-width: 992px) {
        width: 60px;
        height: 60px;
      }
    }
    &__btn {
      background-color: $main-color;
      color: #fff;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.aside__item {
  padding: 16px var(--XL, 16px);
  border-radius: $lg;
  background: #f8fafb;

  @media (max-width: 992px) {
    padding: 8px var(--XL, 8px);
  }

  &.router-link-active,
  &.router-link-exact-active {
    background: $main-color;
    color: #f8fafb;
  }
}

.setting__header__title {
  @media (max-width: 992px) {
    font-size: 12px;
  }
}
.setting__header__text {
  @media (max-width: 992px) {
    font-size: 10px;
  }
}
</style>
