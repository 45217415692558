import WrapperSecond from "./WrapperSecondSide.vue";

export default {
  routes: [
    {
      path: "",
      component: WrapperSecond,
      children: [
        {
          path: "/second-side/:contractId",
          name: "second-side",
          component: () => import("./SecondSide.vue"),
        },
        {
          path: "/second-side-otp",
          name: "second-side-otp",
          component: () => import("./OTP.vue"),
        },
        {
          path: "/check-code",
          name: "CheckCode",
          component: () => import("./Code.vue"),
        },
        {
          path: "page-not-found/:pathMatch(.*)*", // 404
          name: "not-found",
          component: () => import("./NotFound.vue"),
        },
        {
          path: "/nic-success",
          name: "nic-success",
          component: () => import("./SecondSideSuccess.vue"),
        },
        {
          path: "/nic-fail",
          name: "nic-fail",
          component: () => import("./SecondSideFail.vue"),
        },
        {
          path: "/second-side/:contractId/check-nafath",
          name: "check-nafath",
          component: () => import("./CheckNafath.vue"),
        },
      ],
    },
  ],
};
