import { defineStore } from "pinia";
import UserProfile from "@/service/auth/user.service";
import { useToast } from "vue-toastification";
const toast = useToast();

export const useUserProfileStore = defineStore("profileUser", {
  state: () => ({
    isLoading: false,
    account: {},
    isUpload: false,
  }),

  getters: {},

  actions: {
    fetchUser: async function () {
      try {
        this.isLoading = true;
        const { data } = await UserProfile.get();
        // this.user = await data.data;
        this.account = data.data;
      } catch (error) {
        console.log(error);
        // toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    uploadAvatar: async function (avatar) {
      try {
        this.isUpload = true;
        const { data } = await UserProfile.uploadAvatar(avatar);
        // this.account = data.data;
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isUpload = false;
      }
    },
  },
});
