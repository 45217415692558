<template>
  <v-dialog
    :model-value="show"
    @update:modelValue="show"
    @close="$emit('close')"
    width="500"
  >
    <v-card class="px-md-8 px-3 py-6 confirm_dialog" rounded="lg">
      <div class="mx-auto">
        <v-img
          src="/assets/images/users/delete_icon.svg"
          width="56"
          v-if="popup_type == 'delete'"
        ></v-img>
        <div v-if="popup_type == 'edit'">
          <v-img src="/assets/icons/edit_icon.svg" width="56"></v-img>
        </div>
      </div>
      <v-card-title class="text-center confirm-title mt-2">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-center mb-5 pt-0 dark-700">
        {{ message }}
      </v-card-text>
      <div class="d-flex align-center ga-4">
        <wb-button
          class="w-100 main-btn"
          @clicked="$emit('close')"
          :label="$t('FORGET_PASSWORD.back_btn')"
          :isDisabled="isLoading"
          type="grey"
        >
        </wb-button>
        <wb-button
          class="w-100 main-btn"
          @clicked="confirmAction"
          :label="btnLabel || this.$t('EMPLOYEE.DELETE.BUTTON')"
          :type="actionBtnType"
          :isLoading="isLoading"
        >
        </wb-button>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    btnLabel: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    actionBtnType: {
      type: String,
      default: "error",
    },
    popup_type: {
      type: String,
      default: "delete",
    },
  },

  methods: {
    confirmAction() {
      this.$emit("confirm");
    },
  },
};
</script>
<style lang="scss">
.confirm_dialog {
  @media (max-width: 767px) {
    .confirm-title {
      font-size: 17px;
      text-wrap: balance;
    }
  }
}
</style>
