<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div class="heading mt-2 pa-5 mb-6">
        <h2>{{ $t("CONTRACTS_SIGN.TITLE") }}</h2>
        <p class="dark-700">{{ $t("CONTRACTS.SUBTITLE") }}</p>
      </div>
      <!-- <router-link to="/contracts/create" class="main-btn d-md-none">
        <v-icon>mdi mdi-plus</v-icon>
      </router-link> -->
    </div>
    <wb-data-table
      :headers="headers"
      :slots-items="[
        'status',
        'number',
        'actions',
        'send_method',
        'verified_method',
      ]"
      :isLoading="isLoading"
      :items="records"
      :meta="meta"
      :placeholder="$t('CONTRACTS.TABLE.SEARCH_FOR_CONTRACTS')"
      :title="$route.meta.title"
      :text-link="$t('CONTRACTS.ADD.TITLE')"
      page-link="/contracts/create"
      @changePage="changePage"
      @changePerPage="changePerPage"
      @search="search"
      :filtersMenu="filtersMenu"
      @filterItems="filterItems"
      :hideBtn="true"
    >
      <template v-slot:status="{ item }">
        <span class="contract-status" :class="item.item.status.value">
          {{ item.item.status.label }}
        </span>
      </template>
      <template #number="{ item }">
        {{
          numberCounter + item.index + 1 < 10
            ? "0" + (numberCounter + item.index + 1)
            : numberCounter + item.index + 1
        }}
      </template>
      <template #send_method="{ item }">
        <span>{{
          $t(`CONTRACTS.TABLE.${item.item.send_method}`)
        }}</span></template
      >
      <template #verified_method="{ item }">
        <span>{{
          $t(`CONTRACTS.TABLE.${item.item.verified_method}`)
        }}</span></template
      >
      <template #actions="{ item }">
        <v-menu
          location="end"
          width="180px"
          :close-on-content-click="false"
          class="menu-filter"
        >
          <template v-slot:activator="{ props, isActive }">
            <button
              class="w-100 d-flex align-center justify-space-between"
              v-bind="props"
              :style="{ filter: !isActive ? 'grayscale(1)' : '' }"
            >
              <icon-more />
            </button>
          </template>

          <v-list>
            <v-list-item class="pa-0">
              <router-link
                :to="{
                  name: 'contract-sign-view',
                  params: { id: item.item.id },
                }"
                class="d-flex items-center ga-2 px-2 w-100 h-100"
              >
                <v-icon color="grey">mdi mdi-eye-outline</v-icon>
                <span> {{ $t("global.view") }} </span>
              </router-link>
            </v-list-item>
            <v-list-item class="pa-0" v-if="item.item.invitation_url">
              <a
                :href="item.item.invitation_url"
                target="_blank"
                class="d-block w-100"
              >
                <v-icon color="grey" class="ms-2">mdi mdi-pencil</v-icon>
                <span class="ms-2"> {{ $t("global.sign") }} </span>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </wb-data-table>

    <wb-list-cards
      class="d-none"
      :items="records"
      :paginations="meta"
      :hasToggleStatus="false"
      :showCreatedAtDate="false"
      :menuActions="menuActions"
      :cardItems="cardItems"
      @changePage="changePage"
    ></wb-list-cards>
    <DeletePopup
      v-if="openDelete"
      :show="openDelete"
      :title="deleteMessage"
      message="في حالة إذا قمت بتأكيد الحذف فسوف يتم حذف العقد ."
      @close="openDelete = false"
      @confirm="deleteItem"
    />

    <DeletePopup
      v-if="openCancel"
      :show="openCancel"
      :title="cancelMessage"
      message="في حالة إذا قمت بتأكيد  الغاء  فسوف يتم إلغاء العقد ."
      btnLabel="إلغاء"
      @close="openCancel = false"
      @confirm="cancelContract"
    />
  </div>
</template>
<script>
import IconDelete from "@/assets/Icons/IconDelete.vue";
import IconEdit from "@/assets/Icons/IconEdit.vue";
import IconMore from "@/assets/Icons/IconMore.vue";
import { useContractStore } from "@/stores/companies/contract/contract.store";
import { mapActions, mapState } from "pinia";
import { useUserProfileStore } from "@/stores/auth/user.store";
import DeletePopup from "@/components/common/ConfirmModel.vue";
import icons from "@/constants/icons.js";

export default {
  components: {
    IconDelete,
    IconEdit,
    IconMore,
    DeletePopup,
  },
  data() {
    return {
      params: {
        per_page: 8,
        page: 1,
        search: null,
      },
      selectedRow: null,
      openDelete: false,
      openCancel: false,
      tableItems: [
        {
          id: 1,
          name: "Contract 1",
          creation_date: "2021-09-01",
          signature: "الرسائل النصية",
          method: "البريد الإلكتروني",
          status: "pending",
        },
        {
          id: 2,
          name: "Contract 2",
          creation_date: "2021-09-05",
          signature: "النفاذ الوطني",
          method: "البريد الإلكتروني",
          status: "canceled",
        },
        {
          id: 3,
          name: "Contract 3",
          creation_date: "2021-09-12",
          signature: "الرسائل النصية",
          method: "البريد الإلكتروني",
          status: "in_review",
        },
        {
          id: 4,
          name: "Contract 4",
          creation_date: "2021-09-05",
          signature: "النفاذ الوطني",
          method: "البريد الإلكتروني",
          status: "completed",
        },
        {
          id: 5,
          name: "Contract 5",
          creation_date: "2021-09-05",
          signature: "الرسائل النصية",
          method: "البريد الإلكتروني",
          status: "sent",
        },
      ],
      filtersMenu: [
        {
          label: this.$t("global.filters.status.title"),
          items: [],
        },
      ],
    };
  },
  async mounted() {
    this.params[`filters[approval_order]`] = this.account.id;
    await Promise.allSettled([
      this.getPendingSignContracts(this.params),
      this.getStatus(),
    ]);

    this.statuses.forEach((el) => {
      if (el !== "total") {
        this.filtersMenu[0].items.push({
          label: el.label,
          value: el.value,
          selected: false,
          typeFilter: "status",
        });
      }
    });
  },
  computed: {
    ...mapState(useContractStore, ["records", "isLoading", "meta", "statuses"]),
    ...mapState(useUserProfileStore, ["account"]),
    numberCounter() {
      return this.meta.current_page * this.meta.per_page - this.meta.per_page;
    },
    deleteMessage() {
      return `هل أنت متأكد من حذف العقد ${this.selectedRow.title} ؟`;
    },
    cancelMessage() {
      return `هل أنت متأكد من إلغاء العقد ${this.selectedRow.title} ؟`;
    },
    menuActions() {
      return [
        {
          label: this.$t("global.view"),
          icon: icons.view_icon,
          click: (item) => {
            this.$router.push({
              name: "contract-view",
              params: { id: item.id },
            });
          },
        },
        {
          label: this.$t("global.sign"),
          icon: icons.edit_icon,
          click: (item) => {
            window.location.href = item?.invitation_url;
          },
        },
      ];
    },
    cardItems() {
      return [
        {
          label: "CONTRACTS.TABLE.CONTRACT_NAME",
          value: "title",
          type: "text",
          click(item) {},
        },
        {
          label: "CONTRACTS.TABLE.CONFIRM_SIGNATURE",
          customLabel: "CONTRACTS.TABLE.",
          value: "verified_method",
          type: "custom",
          click(item) {},
        },
        {
          label: "CONTRACTS.TABLE.STATUS",
          value: "status",
          type: "status",
          click: (item) => {},
        },
      ];
    },
    headers() {
      return [
        {
          title: this.$t("EMPLOYEE.TABLE.NUMBER"),
          align: "start",
          sortable: false,
          key: "number",
        },
        {
          title: this.$t("CONTRACTS.TABLE.CONTRACT_NAME"),
          align: "start",
          sortable: false,
          key: "title",
          width: "calc(95% / 6)",
        },
        {
          title: this.$t("CONTRACTS.TABLE.SENDING_METHOD"),
          align: "start",
          sortable: false,
          key: "send_method",
        },
        {
          title: this.$t("CONTRACTS.TABLE.CREATION_DATE"),
          align: "start",
          sortable: false,
          key: "created_at",
        },
        {
          title: this.$t("CONTRACTS.TABLE.CONFIRM_SIGNATURE"),
          align: "start",
          sortable: false,
          key: "verified_method",
        },
        {
          title: this.$t("CONTRACTS.TABLE.STATUS"),
          align: "start",
          sortable: false,
          key: "status",
        },
        {
          title: "",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ];
    },
    items() {
      return this.records.map((item) => {
        return {
          ...item,
          name: item?.title,
          creation_date: item?.created_at,
          last_update: item?.last_updated,
          status: item?.status,
          workflow: item?.workflow ? item?.workflow : "---",
        };
      });
    },
    tabs() {
      return [
        {
          label: this.$t("CONTRACTS.TABS.ALL_CONTRACTS"),
          action: null,
          icon: "/assets/images/contracts/file.svg",
          key: "total",
        },
        {
          label: this.$t("CONTRACTS.TABS.COMPLETED"),
          action: "completed",
          icon: "/assets/images/contracts/check-circle.svg",
          key: "completed",
        },
        {
          label: this.$t("CONTRACTS.TABS.UNDER_REVIEW"),
          action: "in_review",
          icon: "/assets/images/contracts/hourglass-end.svg",
          key: "in_review",
        },
        {
          label: "مرسل",
          action: "sent",
          icon: "/assets/images/contracts/exclamation.svg",
          key: "sent",
        },
        {
          label: this.$t("CONTRACTS.TABS.CANCELLED"),
          action: "canceled",
          icon: "/assets/images/contracts/cross-circle.svg",
          key: "canceled",
        },
      ];
    },
    calcHeight() {
      return {
        "max-height": "calc(100dvh - 350px)",
      };
    },
  },
  methods: {
    ...mapActions(useContractStore, [
      "getPendingSignContracts",
      "getStatus",
      "delete",
      "closeContract",
    ]),

    async filterItems(filter) {
      this.params.page = 1;
      await this.getPendingSignContracts(
        {
          ...this.params,
        },
        filter,
        true
      );
    },

    async cancelContract() {
      await this.closeContract(this.selectedRow.id);
      await this.getPendingSignContracts({
        per_page: this.meta.per_page,
        page: this.meta.current_page,
        search: this.params.search,
      });

      this.openCancel = false;
    },

    getContractDetails(item) {
      this.$router.push({
        name: "contract-sign-view",
        params: { id: item.id },
      });
    },

    async changePage(page) {
      this.params.page = page;
      await this.getPendingSignContracts(this.params);
    },
    async changePerPage(perPage) {
      this.params.per_page = perPage;
      this.params.page = 1;
      await this.getPendingSignContracts(this.params);
    },
    async search(search) {
      this.params.search = search;
      this.page = 1;
      await this.getPendingSignContracts(this.params);
    },

    async changeTab(tab) {
      this.params.status = tab;
      await this.getPendingSignContracts({
        per_page: this.meta.per_page,
        page: this.meta.current_page,
        search: this.params.search,
      });
    },
    async deleteItem() {
      const res = await this.delete(this.selectedRow.id);
      if (res) {
        await this.getPendingSignContracts({
          per_page: this.meta.per_page,
          page: this.meta.current_page,
          search: this.params.search,
        });
        this.openDelete = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  &__btn {
    border-radius: $xl;
    background: $global-white;
    padding: $xl;
    color: $global-400;
    border: 1px solid transparent;

    &__text {
      color: var(--Global-400, #474747);
      font-size: 14px;
      font-weight: 500;
    }

    &__counter {
      border-radius: $xl;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      filter: grayscale(1);
    }

    &.active {
      border: 1px solid $main-color;
    }

    &.active &__icon {
      filter: grayscale(0);
    }
  }

  .logo-tab {
    position: absolute;
    transform: translateX(-50%);
    top: -15px;
    left: 59%;
  }
}
</style>
