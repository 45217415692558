import { defineStore } from "pinia";
import SecondSide from "@/service/secondSide/secondSide.service";
import { useToast } from "vue-toastification";
import router from "@/router";

export const useSecondSideStore = defineStore("secondSide", {
  state: () => ({
    isLoading: false,
    contract: null,
    err: false,
    isCancelled: false,
    cancelledMsg: null,
  }),

  getters: {},

  actions: {
    sendOTP: async function () {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.sendOTP();
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    verifyOTP: async function (otp) {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.verifyOTP(otp);
        toast.success(data.message);
        return data;
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    getContract: async function (contractId) {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.getContract(contractId);
        this.contract = data.data;
      } catch (error) {
        toast.error(error.response.data.message);
        if (error.response.data.status == "ALREADY_SIGNED") {
          router.push({ name: "signature-success" });
        }
        if (error.response.status == 333) {
          return await router.replace({
            name: "signature-success",
            query: { status: error.response.status },
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    addComment: async function (contractId, comment) {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.addComment(contractId, comment);
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    uploadSignature: async function (contractId, body) {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.uploadSignature(contractId, body);
        toast.success(data.message);
        return true;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    approveFinalContract: async function (userId, contractId) {
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.approveFinalContract(
          userId,
          contractId
        );
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },

    nafathAuthorize: async function (id, contract_id) {
      this.isCancelled = false;
      this.cancelledMsg = null;
      this.err = false;
      const toast = useToast();
      try {
        this.isLoading = true;
        const { data } = await SecondSide.nafathAuthorize(id);
        console.log(data, "data");
        if (data.status === "NAFATH_TIMEOUT") {
          toast.error(data.message);
        }
        return data;
      } catch (error) {
        console.log(error, "error");
        if (error.response?.status == 336) {
          this.err = true;
        }
        if (error.response?.status == 331) {
          this.isCancelled = true;
          this.cancelledMsg = error.response?.data?.message;
        }
        if (error.response?.status == 332) {
          return await router.replace(
            `/sing-contract/${router.currentRoute.value.query.token}/${contract_id}?token=${router.currentRoute.value.query.token}`
          );
        }
        if (error.response.status == 333) {
          return await router.replace({
            name: "signature-success",
            query: { status: error.response.status },
          });
        }
        if (
          error.response.data.data?.status !== "ALREADY_SIGNED" &&
          error.response?.status != 331
        ) {
          toast.error(error.response.data.message);
        }
        return error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
