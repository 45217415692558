<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    :style="{ transform: isActive ? 'rotate(180deg)' : '' }"
  >
    <path
      d="M15 12.5L10 7.5L5 12.5"
      stroke="#51A79A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    isActive: Boolean,
    default: false,
  },
};
</script>
<style lang=""></style>
