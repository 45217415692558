import { defineStore } from "pinia";
import Workflow from "@/service/companies/workflow/workflow.service";
import { useToast } from "vue-toastification";
const toast = useToast();

export const useWorkflowsStore = defineStore("workflows", {
	state: () => ({
		records: [],
		meta: {},
		uiFlags: {
			isLoading: false,
			isCreating: false,
			isUpdating: false,
			isDeleting: false,
		},
	}),

	getters: {},

	actions: {
		async fetchWorkflow(params) {
			try {
				this.uiFlags.isLoading = true;
				const { data } = await Workflow.get(params);
				this.records = data.data;
				this.meta = data.meta;
			} catch (error) {
				console.log(error);
			} finally {
				this.uiFlags.isLoading = false;
			}
		},
		async createWorkflow(item) {
			try {
				this.uiFlags.isCreating = true;
				const { data } = await Workflow.create(item);
				if (this.records.length < this.meta.per_page) {
					this.records.push(data.data);
				}
				toast.success(data.message);
				return true;
			} catch (error) {
				toast.error(error.response.data.message);
				return false;
			} finally {
				this.uiFlags.isCreating = false;
			}
		},
		async updateWorkflow(id, item) {
			try {
				this.uiFlags.isUpdating = true;
				const { data } = await Workflow.update(id, item);
				const findItem = this.records.find((item) => item.id === id);
				Object.assign(findItem, data.data);
				toast.success(data.message);
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				this.uiFlags.isUpdating = false;
			}
		},
		async deleteWorkflow(id) {
			try {
				this.uiFlags.isDeleting = true;
				const { data } = await Workflow.delete(id);
				this.records = this.records.filter((record) => record.id !== id);
				toast.success(data.message);
			} catch (error) {
				toast.error(error.response.data.message);
			} finally {
				this.uiFlags.isDeleting = false;
			}
		},
	},
});
