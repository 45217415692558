<template>
  <div class="toast">
    <div
      class="toast__content"
      :class="{
        error: 'error',
        success: 'success',
        info: 'info',
      }"
    >
      <span class="d-block mt-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="#FA4545"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span>تحديث الصفحة فشل في تحميل مستند العقد</span>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.toast {
  position: relative;
  &__content {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    gap: 12px;
    padding: var(--L, 12px);

    border-radius: var(--L, 12px);
    box-shadow: 0px 2px 16px 0px rgba(10, 10, 10, 0.15);
    &.error {
      background: #fff1f1;
    }
  }
}
</style>
