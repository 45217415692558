import { defineStore } from "pinia";
import Payment from "@/service/payment/payment.service";
import { useToast } from "vue-toastification";
const toast = useToast();
export const usePaymentStore = defineStore("Payment", {
  state: () => ({
    isLoading: false,
    plan: [],
    subscriptionDetails: {},
    singleInvoice: {},
    periods: [],
    plans: [],
    isCancelled: false,
    cancelledMsg: null,
  }),
  getters: {},
  actions: {
    createPayment: async function (obj) {
      this.isCancelled = false;
      this.cancelledMsg = null;
      try {
        this.isLoading = true;
        const { data } = await Payment.create_callback(obj);
        return data;
      } catch (error) {
        if (error.response?.status == 331) {
          this.isCancelled = true;
          this.cancelledMsg = error.response.data.message;
        }
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    getPayment: async function () {
      try {
        this.isLoading = true;
        const { data } = await Payment.get();
        this.plan = data.data;
        return data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },

    getCurrentSubscription: async function () {
      try {
        this.isLoading = true;
        const { data } = await Payment.getSubscriptionData();
        this.subscriptionDetails = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    createInvoice: async function (obj) {
      try {
        this.isLoading = true;
        const { data } = await Payment.invoice(obj);
        if (data.data.url) {
          window.location.replace(data.data.url);
        }
        return data;
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    payNow: async function () {
      try {
        const { data } = await Payment.payNow();
        window.location.replace(data.data.invoice_url);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    subscriptionPaymentCallback: async function (obj) {
      try {
        const { data } = await Payment.subscriptionPaymentCallback(obj);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    viewInvoices: async function (id) {
      try {
        this.isLoading = true;
        const { data } = await Payment.viewInvoices(id);
        this.singleInvoice = data.data;
        return data;
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    getPeriods: async function () {
      try {
        this.isLoading = true;
        const { data } = await Payment.getPeriods();
        this.periods = data.data.time_periods;
        return data;
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getPlans(period) {
      try {
        this.isLoading = true;
        const { data } = await Payment.getPlans(period);
        if (data.data.plans.length >= 3) {
          const activeEl = data.data.plans.find((el) => el.is_recommended);
          if (activeEl) {
            const activeIndex = data.data.plans.indexOf(activeEl);
            let temp = data.data.plans[activeIndex];
            data.data.plans[activeIndex] = data.data.plans[1];
            data.data.plans[1] = temp;
          }
        }
        this.plans = data.data.plans;
        return data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message);
      } finally {
        this.isLoading = false;
      }
    },
    subscripe: async function (body) {
      try {
        this.isLoading = true;
        const { data } = await Payment.subscripe(body);
        window.location.replace(data.data.invoice_url);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    customPlan: async function (body) {
      try {
        const { data } = await Payment.customPlan(body);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      }
    },
  },
});
