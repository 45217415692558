import {roleGuard} from "@/helper/router.helper";
import createContractsRoute from "./CreateContract/create.contracts.route";
import updateContractsRoute from "./CreateContract/update.contracts.route";
import WrapperContracts from "./WrapperContracts.vue";
import ContractsList from "./ContractsList.vue";

export default {
  routes: [
    {
      path: "contracts",
      component: WrapperContracts,
      redirect: {name: "contracts-list"},
      children: [
        {
          component: ContractsList,
          path: "/contracts",
          name: "contracts-list",
          meta: {
            title: "SIDEBAR.CONTRACTS",
            pageType: "SIDEBAR.CONTRACTS",
            roles: ["admin", "template_moderator", "authorized_employee"],
          },
          beforeEnter: roleGuard,
        },
        {
          component: () => import("./ContractPreview.vue"),
          path: "/contracts/preview/:id",
          name: "contract-view",
          meta: {
            title: "SIDEBAR.CONTRACTS",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin", "template_moderator", "authorized_employee"],
            wide: true
          },
          beforeEnter: roleGuard,
        },
        {
          path: "/contracts/create",
          name: "contract-create",
          component: () => import("./CreateContract/WrapperCreate.vue"),
          meta: {
            title: "SIDEBAR.CREATE_CONTRACT",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin", "template_moderator", "authorized_employee"],
            wide: true
          },
          beforeEnter: roleGuard,
          redirect: {name: "contract-create-information"},
          children: [...createContractsRoute.routes],
        },
        {
          path: "/contracts/update",
          name: "contract-update",
          component: () => import("./CreateContract/WrapperCreate.vue"),
          meta: {
            title: "SIDEBAR.UPDATE_CONTRACT",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin", "template_moderator", "authorized_employee"],
            wide: true
          },
          beforeEnter: roleGuard,
          redirect: {name: "contract-update-information"},
          children: [...updateContractsRoute.routes],
        },
        {
          path: "contracts/:id/:mode",
          name: "contract-details",
          component: () => import("./EditContract/ContractDetails.vue"),
          meta: {
            title: "SIDEBAR.CONTRACTS_DETAILS",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin", "template_moderator", "authorized_employee"],
          },
          beforeEnter: roleGuard,
        },
      ],
    },
    {
      path: "nic-payment",
      name: "nic-payment",
      component: () => import("./NICPayment/NicPaymentCallback.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS",
        pageType: "SIDEBAR.employee_type",
        roles: ["admin", "employee"],
      },
    },
  ],
};
