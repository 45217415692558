<template>
  <v-card
    v-on-click-outside="close"
    max-width="400"
    :width="$vuetify.display.width > 600 ? 400 : 'unset'"
    class="pa-6"
    rounded="lg"
    variant="flat"
    elevation="0"
    style="box-shadow: -10px 22px 48px 0px rgba(0, 0, 0, 0.08) !important"
  >
    <div class="d-flex align-center ga-2 justify-space-between">
      <div style="font-size: 16px !important; color: #050505">
        {{ $i18n.locale == "ar" ? "الاشعارات" : "Notifications" }}
      </div>
      <div>
        <v-btn icon flat>
          <v-icon color="dark-500"> mdi mdi-close </v-icon>
        </v-btn>
      </div>
    </div>
    <v-list max-height="350" v-if="records.length">
      <v-list-item
        class="cursor-pointer my-3 py-6 px-4 bg-dark-50"
        border
        rounded="lg"
        v-for="(item, index) in records.slice(0, 5)"
        :key="index"
        @click="
          $router.push({
            name: 'contract-view',
            params: { id: item.notification.contract_id },
          }),
            $emit('closeNotification', $event)
        "
      >
        <template #prepend>
          <span class="me-2">
            <img :src="icons[item.notification.action]" />
          </span>
        </template>
        <v-list-item-title>
          <div
            class="d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between"
          >
            <div
              style="
                font-size`: 16px !important;
                color: #0a0a0a;
                font-weight: 500;
              "
            >
              <router-link
                :to="{
                  name: 'contract-view',
                  params: { id: item.notification.contract_id },
                }"
              >
                {{ item?.notification?.title }}
              </router-link>
            </div>
            <div
              style="font-size: 12px"
              class="text-dark-400 d-flex align-center ga-1"
            >
              {{ item?.created_at }}
              <span class="mdi mdi-18px mdi-clock-outline"></span>
            </div>
          </div>
        </v-list-item-title>
        <v-list-item-subtitle
          style="font-size: 14px !important"
          class="text-dark-600"
        >
          {{ item?.notification?.body }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <template v-else>
      <v-card variant="flat" elevation="0">
        <v-img
          src="/assets/images/empty.svg"
          max-width="180"
          class="mx-auto"
        ></v-img>
        <v-card-title class="font-weight-bold text-center">
          {{
            $i18n.locale == "ar"
              ? "ليس لديك أي إشعارات حاليًا"
              : "You currently have no notifications."
          }}
        </v-card-title>
        <v-card-subtitle
          style="white-space: normal !important"
          class="text-center"
        >
          {{
            $i18n.locale == "ar"
              ? "ستظهر هنا جميع الإشعارات المتعلقة بالعقود والإجراءات الأخرى عند توفرها"
              : "All notices regarding contracts and other procedures will appear here when available."
          }}
        </v-card-subtitle>
      </v-card>
    </template>
    <v-card-actions class="mt-2">
      <wb-button
        class="w-100"
        @clicked="
          $router.push({
            name: 'NotificationsList',
          })
        "
        :label="$t('global.notifications')"
        type="outlined"
      >
      </wb-button>
    </v-card-actions>
  </v-card>
</template>
<script>
import { vOnClickOutside } from "@vueuse/components";
import { useNotificationsStore } from "@/stores/companies/notifications/notifications.store";
import { mapActions, mapState } from "pinia";
import { useUserProfileStore } from "@/stores/auth/user.store";
import icons from "@/constants/icons.js";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  async created() {},
  async mounted() {
    await this.get();
    this.readAll();
  },

  computed: {
    icons() {
      return icons;
    },
    ...mapState(useNotificationsStore, ["records"]),
    ...mapState(useUserProfileStore, ["account"]),
  },
  methods: {
    ...mapActions(useNotificationsStore, ["get", "readAll"]),
    close(event) {
      const target = event.target;
      if (!target.closest(".upper-bar__notifications")) {
        this.$emit("closeNotification", event);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no_notifs {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: grey;
}

.notification {
  width: 495px;
  position: absolute;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px var(--6xl, 48px) -8px rgba(5, 5, 5, 0.12);
  z-index: 9999;
  top: 50px;
  padding: 8px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 50%;
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 500px;
    overflow-y: auto;
    padding: 8px;

    &__item {
      border-radius: var(--XL, 16px);
      border-bottom: 1px solid #f5f5f5;
      padding: 16px;
      font-size: 14px;
      transition: 0.2s;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #f5f5f5;
      }
    }

    &__icon {
      background: var(--Primary-50, #fafafa);
      width: 60px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
