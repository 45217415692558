<template>
  <div class="w-100 position-relative mb-6" v-if="!isLoading">
    <div
      class="upper-bar d-flex justify-space-between align-center px-md-6 py-md-4 pa-2 position-relative w-100"
    >
      <div class="d-flex align-center ga-2">
        <button @click="$emit('toggleAside')" class="toggle-btn">
          <img src="/assets/images/upperbar/toggle.svg" />
        </button>
        <h2 class="title-page">
          {{ $t($route.meta.title) }}
        </h2>
      </div>
      <div class="d-flex ga-4 align-center">
        <div class="position-relative">
          <v-menu offset="10">
            <template v-slot:activator="{ props }">
              <button
                v-bind="props"
                class="upper-bar__notifications d-flex align-center justify-center"
                @click="toggleNotification"
              >
                <span
                  class="upper-bar__notifications__number d-flex align-center justify-center"
                  v-if="notifs?.filter((item) => !item?.is_read).length"
                >
                  {{ notifs?.filter((item) => !item?.is_read).length || 0 }}
                </span>
                <img
                  src="/assets/images/upperbar/notifications.svg"
                  alt="notifications"
                  class="upper-bar__notifications__icon"
                  width="60"
                />
              </button>
            </template>
            <notifications
              :account="account"
              @closeNotification="closeNotification"
            />
          </v-menu>
        </div>
        <button
          class="position-relative d-md-flex align-center ga-2 lang-content d-none"
          @click="changeLanguage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M2 12.5H22M2 12.5C2 18.0228 6.47715 22.5 12 22.5M2 12.5C2 6.97715 6.47715 2.5 12 2.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5M22 12.5C22 6.97715 17.5228 2.5 12 2.5M12 2.5C14.5013 5.23835 15.9228 8.79203 16 12.5C15.9228 16.208 14.5013 19.7616 12 22.5M12 2.5C9.49872 5.23835 8.07725 8.79203 8 12.5C8.07725 16.208 9.49872 19.7616 12 22.5"
              stroke="#5C5C5C"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="$i18n.locale == 'ar'">English</span>
          <span v-if="$i18n.locale == 'en'">العربية</span>
        </button>

        <v-menu class="menu-filter">
          <template v-slot:activator="{ props }">
            <button
              class="d-flex ga-md-2 align-center text-start"
              v-bind="props"
            >
              <v-avatar size="48px">
                <v-img alt="Avatar" :src="account.avatar"></v-img>
              </v-avatar>
              <div class="d-none d-md-block">
                <h4 class="d-block text-account-name">
                  {{ account?.first_name }} {{ account?.last_name }}
                </h4>
                <span class="d-block text-role my-0">
                  {{ account?.role?.label }}
                </span>
              </div>
              <div class="">
                <i class="mdi mdi-24px mdi-chevron-down"></i>
              </div>
            </button>
          </template>
          <v-list>
            <v-list-item class="py-2">
              <router-link to="/profile" class="d-flex align-center ga-md-2">
                <span class="menu__icon">
                  <img src="/assets/images/upperbar/user_icon.svg" alt="user" />
                </span>
                <v-list-item-title>
                  {{ $t("UPPER_NAVBAR.PROFILE") }}
                </v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item class="py-2 d-md-none">
              <button
                class="position-relative d-md-flex align-center ga-2 ps-3"
                @click="changeLanguage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  class="me-2"
                  style="transform: translateY(4px)"
                >
                  <path
                    d="M2 12.5H22M2 12.5C2 18.0228 6.47715 22.5 12 22.5M2 12.5C2 6.97715 6.47715 2.5 12 2.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5M22 12.5C22 6.97715 17.5228 2.5 12 2.5M12 2.5C14.5013 5.23835 15.9228 8.79203 16 12.5C15.9228 16.208 14.5013 19.7616 12 22.5M12 2.5C9.49872 5.23835 8.07725 8.79203 8 12.5C8.07725 16.208 9.49872 19.7616 12 22.5"
                    stroke="#5C5C5C"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span
                  v-if="$i18n.locale == 'ar'"
                  style="font-size: 18px !important"
                  >English</span
                >
                <span
                  v-if="$i18n.locale == 'en'"
                  style="font-size: 18px !important"
                  >العربية</span
                >
              </button>
            </v-list-item>
            <v-list-item class="py-2">
              <button class="d-flex align-center ga-md-2" @click="logout">
                <span class="menu__icon">
                  <img src="/assets/images/upperbar/logout.svg" alt="logout" />
                </span>
                <v-list-item-title>
                  {{ $t("UPPER_NAVBAR.LOGOUT") }}
                </v-list-item-title>
              </button>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "./Notifications.vue";
import { useUserProfileStore } from "@/stores/auth/user.store";
import { useAuthStore } from "@/stores/auth/auth.store";
import { mapActions, mapState } from "pinia";
import { useCompanyStore } from "@/stores/companies/profile/profile.store";
import { useNotificationsStore } from "@/stores/companies/notifications/notifications.store";

export default {
  props: {
    show: Boolean,
  },
  components: {
    Notifications,
  },
  setup() {
    return {};
  },
  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["account"]),
    ...mapState(useCompanyStore, ["record", "isLoading"]),
    ...mapState(useNotificationsStore, {
      notifs: "notifs",
    }),
  },
  methods: {
    ...mapActions(useAuthStore, ["logout"]),
    ...mapActions(useNotificationsStore, ["get", "readAll"]),

    closeNotification() {
      this.isOpen = false;
    },
    toggleNotification() {
      this.isOpen = !this.isOpen;
    },
    changeLanguage() {
      localStorage.setItem(
        "localeLang",
        this.$i18n.locale === "en" ? "ar" : "en"
      );
      window.location.reload();
    },
  },
  async mounted() {
    await this.get();
    this.$echo
      .private(`notifications.${this.account.id}`)
      .notification(async () => {
        await this.get();
      });
  },
};
</script>

<style lang="scss" scoped>
.upper-bar {
  background: var(--Global-White, #fff);
  border-radius: var(--Sm, 12px);
  z-index: 1;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   transform: translateY(-50%);
  //   width: 5px;
  //   height: 32px;
  //   border-radius: var(--Sm, 6px) var(--none, 0px) var(--none, 0px)
  //     var(--Sm, 6px);
  //   background: $main-color;
  // }
  &__notifications {
    border-radius: 50%;
    background: #f8fafb;
    width: 49px;
    height: 49px;
    position: relative;
    z-index: 9999999999999999999999999999999999999999999999999999999;
    @media (max-width: 992px) {
      width: 32px;
      height: 32px;
    }

    &__number {
      position: absolute;
      top: -5px;
      right: -17px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border-radius: 1000px;
      border: 2px solid #fff;
      background: #dc0031;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .title-page {
    color: var(--Global-900, #050505);
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
}

.menu {
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: #f8fafb;
  }
}

.text-role {
  color: var(--Global-300, #757575);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 6px;
  @media (max-width: 992px) {
    font-size: 10px;
  }
}

.account__status {
  @media (max-width: 992px) {
    font-size: 10px;
  }
}

.text-account-name {
  color: var(--Global-900, #050505);
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 992px) {
    font-size: 12px;
  }
}

.upper-bar__user-mobile {
  @media (max-width: 992px) {
    overflow-x: auto;
  }
}

.toggle-btn {
  @media (max-width: 992px) {
    width: 32px;
    height: 32px;
    overflow: hidden;
  }
}

.lang-content {
  @media (min-width: 992px) {
    padding: 0 16px;
    border-inline-end: 2px solid var(--02---Dark-Dark---100, #f5f5f5);
    border-inline-start: 2px solid var(--02---Dark-Dark---100, #f5f5f5);
  }
}
</style>
