import Notifications from "@/service/notifications/notifications.service";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toast = useToast();

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    records: [],
    notifs: [],
    isLoading: false,
    meta: {},
  }),

  getters: {},

  actions: {
    get: async function (params = {}, loadMore = false) {
      try {
        this.isLoading = true;
        const { data } = await Notifications.get(params);
        this.records = loadMore ? [...this.records, ...data.data] : data.data;
        this.notifs = data.data;
        this.meta = data.meta;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    readAll: async function () {
      try {
        this.isLoading = true;
        const { data } = await Notifications.readAll();
        this.get();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
