import router from "@/router";
import Contract from "@/service/companies/contract/contract.service";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
const toast = useToast();
export const useContractStore = defineStore("contract", {
  state: () => ({
    records: [],
    sent_contracts: [],
    in_review_contracts: [],
    isLoading: false,
    inReviewLoading: false,
    sentLoading: false,
    meta: {},
    sent_meta: {},
    in_review_meta: {},
    statuses: [],
    contract: {},
    places: [],
    comments: [],
    commentIsLoading: false,
    history: [],
    historyIsLoading: false,
    data_retrived: false,
    // object for create contract
    contractObj: {
      // step 1
      title: null,
      send_method: "email",
      verified_method: "sms",
      contract_file: null,
      approvals: [],

      // step 2
      authorized_employee_id: null,
      invitations: [],

      // template
      template_id: null,
      template_values: [],
    },
    isCancelled: false,
    cancelledMsg: null,
  }),

  getters: {},

  actions: {
    changeDataRetrive(val) {
      this.data_retrived = val;
    },
    // contracts
    getContracts: async function (params, filter) {
      try {
        this.isLoading = true;
        const { data } = await Contract.get(params, filter);
        this.records = data.data;
        this.meta = data.meta;

        this.emptyContractObj();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getPendingSignContracts: async function (params, filter) {
      try {
        this.isLoading = true;
        const { data } = await Contract.getPendingSign(params, filter);
        this.records = data.data;
        this.meta = data.meta;

        this.emptyContractObj();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getSentContracts: async function (params, filter) {
      try {
        this.sentLoading = true;
        const { data } = await Contract.get(params, filter);
        this.sent_contracts = data.data;
        this.sent_meta = data.meta;

        this.emptyContractObj();
      } catch (error) {
        console.log(error);
      } finally {
        this.sentLoading = false;
      }
    },

    getInReviewContracts: async function (params, filter) {
      try {
        this.inReviewLoading = true;
        const { data } = await Contract.get(params, filter);
        this.in_review_contracts = data.data;
        this.in_review_meta = data.meta;

        this.emptyContractObj();
      } catch (error) {
        console.log(error);
      } finally {
        this.inReviewLoading = false;
      }
    },

    // contract CRUD
    show: async function (id) {
      this.contract.approvals = [];
      try {
        this.isLoading = true;
        const { data } = await Contract.show(id);
        this.contract = data.data;
        this.places = data.data.forms;
        this.contract.allInvitations = JSON.parse(
          JSON.stringify(data.data.invitations)
        );

        for (const val of Object.entries(this.contract)) {
          if (this.contract[val[0]] && val[0] != "invitations") {
            this.contract[val[0]] = this.contract[val[0]];
          }
        }

        this.contract.contract_file = [...data.data.files];
        this.contract.approvals = data.data.employees
          ? [...data.data.employees].map((el) => {
              return {
                id: el.id.toString(),
                first_name: el.first_name,
                last_name: el.last_name,
                name: `${el.first_name} ${el.last_name}`,
              };
            })
          : [];

        this.contract.invitations = data.data.invitations
          .filter((el) => !el.is_authorized_employee)
          .map(
            (el) =>
              (el = {
                ...el,
                valid_number: true,
              })
          );
        this.contract.invitations.forEach((el) => (el.isEditable = false));
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    createStepTwo: async function (obj) {
      try {
        this.isLoading = true;

        const { data } = await Contract.createStepTwo(obj);
        // if (this.records.length < this.meta.per_page) {
        //   this.records.unshift(data.data);
        // }
        toast.success(data.message);

        await router.push({
          name: "contract-create-signature",
          params: { id: data.data.id },
        });

        this.emptyContractObj();
        return true;
      } catch (error) {
        console.log(error, "error");

        toast.error(error.response?.data?.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    update: async function (id) {
      try {
        this.isLoading = true;

        const formData = new FormData();
        formData.append("title", this.contract.title);
        formData.append("send_method", this.contract.send_method);
        formData.append("verified_method", this.contract.verified_method);
        if (!this.contract.contract_file[0]) {
          formData.append("contract_file", this.contract.contract_file);
        }

        formData.append(
          "authorized_employee_id",
          this.contract.authorized_employee_id
        );

        // Append invitations using map
        this.contract.approvals.map((approval, index) => {
          formData.append(`approvals[${index}]`, parseInt(approval.id));
        });

        // Append invitations using map
        this.contract.invitations.map((invitation, index) => {
          formData.append(`invitations[${index}][name]`, invitation.name);
          formData.append(`invitations[${index}][phone]`, invitation.phone);
          formData.append(`invitations[${index}][email]`, invitation.email);
          formData.append(
            `invitations[${index}][country_code]`,
            invitation.country_code
          );
          formData.append(
            `invitations[${index}][id_number]`,
            invitation.id_number
          );
        });

        const { data } = await Contract.update(formData, id);
        // if (this.records.length < this.meta.per_page) {
        //   this.records.unshift(data.data);
        // }
        toast.success(data.message);
        router.push({
          name: "contract-create-signature",
          params: { id },
        });
        return true;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    delete: async function (id) {
      try {
        this.isLoading = true;
        const { data } = await Contract.deleteContract(id);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    // status
    getStatus: async function () {
      try {
        this.isLoading = true;
        const { data } = await Contract.get_status();
        this.statuses = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    // places variables
    sendPlaces: async function (id, obj, isDraft = false) {
      try {
        this.isLoading = true;
        const { data } = await Contract.create_places(id, obj);
        if (isDraft) {
          toast.success(data.message);
        }
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    // send and approve contract
    sendContractToApprove: async function (id, showMassage = true) {
      try {
        // this.isLoading = true;
        const { data } = await Contract.send_contract(id);
        if (showMassage) {
          toast.success(data.message);
        }

        return data.data;
      } catch (error) {
        if (showMassage) {
          toast.error(error.response.data.message);
        }
        return error.response.data;
      } finally {
        // this.isLoading = false;
      }
    },

    // approve contract form workflow
    approveContract: async function (id) {
      try {
        this.isLoading = true;
        const { data } = await Contract.approve_contract(id);
        toast.success(data.message);
        await this.show(id);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    // history
    getHistory: async function (id) {
      try {
        this.historyIsLoading = true;
        const { data } = await Contract.get_contract_history(id);
        this.history = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.historyIsLoading = false;
      }
    },

    // comments
    getComments: async function (id) {
      try {
        this.commentIsLoading = true;
        const { data } = await Contract.get_all_comments(id);
        this.comments = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.commentIsLoading = false;
      }
    },

    sendComment: async function (id, obj) {
      try {
        this.commentIsLoading = true;
        const { data } = await Contract.send_comment(id, obj);
        this.comments.push(data.data);
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.commentIsLoading = false;
      }
    },
    // update contract data
    updateContractData: async function (id, obj) {
      try {
        this.isLoading = true;
        const { data } = await Contract.update_contract_data(id, obj);
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    // update contract file
    updateContractFile: async function (id, obj) {
      try {
        this.isLoading = true;
        const { data } = await Contract.update_contract_file(id, obj);
        toast.success(data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    // clone contract
    cloneContract: async function (id) {
      try {
        this.isLoading = true;
        const { data } = await Contract.clone_contract(id);
        toast.success(data.message);
        router.push({
          name: "contract-details",
          params: { id: data.data.contract_id, mode: "edit" },
        });
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    // close contract
    closeContract: async function (id) {
      try {
        this.isLoading = true;
        const { data } = await Contract.close_contract(id);
        toast.success(data.message);
        return true;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    SendContractSmsOtp: async function (id) {
      this.isCancelled = false;
      this.cancelledMsg = null;
      try {
        this.isLoading = true;
        const { data } = await Contract.sendSmsOtp(id);
        toast.success(data.message);
        return true;
      } catch (error) {
        if (error.response.status == 330)
          return await router.replace({ name: "not-found" });

        if (error.response?.status == 331) {
          this.isCancelled = true;
          this.cancelledMsg = error.response?.data?.message;
        }

        if (error.response.status == 333) {
          return await router.replace({
            name: "signature-success",
            query: { status: error.response.status },
          });
        }

        return false;
      } finally {
        this.isLoading = false;
      }
    },

    verifyContractSmsOtp: async function (otp) {
      try {
        this.isLoading = true;
        const { data } = await Contract.verifySmsOtp(otp);
        return data.data.contract_id;
      } catch (error) {
        toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    emptyContractObj: function () {
      this.contractObj = {
        // step 1
        title: null,
        send_method: "email",
        verified_method: "sms",
        contract_file: null,
        approvals: [],

        // step 2
        authorized_employee_id: null,
        invitations: [],
      };
    },

    createStepOne: async function (createObj) {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("title", createObj.title);
        formData.append("send_method", createObj.send_method);
        formData.append("verified_method", createObj.verified_method);
        if (createObj.contract_file !== null) {
          formData.append("contract_file", createObj.contract_file);
        }

        if (createObj?.template_id) {
          formData.append("template_id", createObj.template_id);

          if (createObj.approvals.length) {
            createObj.approvals?.map((approval, index) => {
              formData.append(
                `approvals[${index}]`,
                parseInt(approval.user_id ?? approval.id ?? approval)
              );
            });
          }
        } else {
          formData.delete("template_id");

          if (createObj.approvals.length) {
            createObj.approvals?.map((approval, index) => {
              formData.append(
                `approvals[${index}]`,
                parseInt(approval.user_id ?? approval.id ?? approval)
              );
            });
          }
        }

        if (createObj?.template_values) {
          createObj.template_values.map((item, index) => {
            formData.append(
              `template_values[${index}][template_form_id]`,
              item.template_form_id
            );
            formData.append(`template_values[${index}][value]`, item.value);
          });
        }

        if (createObj.contract_id) {
          formData.append("contract_id", createObj.contract_id);
        } else {
          formData.delete("contract_id");
        }

        const { data } = await Contract.createStepOne(formData);
        toast.success(data.message);

        return data;
      } catch (error) {
        if (error?.response) {
          toast.error(error?.response?.data?.message);
        } else {
          console.log(error);
        }
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    getUserInfo: async function (token) {
      try {
        this.isLoading = true;
        const { data } = await Contract.getUserInfo(token);
        return data.data;
      } catch (error) {
        // toast.error(error.response.data.message);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
