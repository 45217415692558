import { useUserProfileStore } from "@/stores/auth/user.store";

export const authGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey("token_wtheq_app");

  if (tokenExists) {
    console.log("Auth Guard: User is logged in");
    return next("/dashboard");
  }
  console.log("Auth Guard: User is not logged in");
  return next();
};

export const guestGuard = async (to, from, next) => {
  const userStore = useUserProfileStore();
  const tokenExists = $cookies.isKey("token_wtheq_app");

  if (Object.keys(userStore.account).length === 0 && tokenExists) {
    await userStore.fetchUser();
  }

  if (!tokenExists) {
    console.log("Guest Guard: User is not logged in");
    return next("/");
  }
  console.log("Guest Guard: User is logged in");
  return next();
};

export const roleGuard = async (to, from, next) => {
  const tokenExists = $cookies.isKey("token_wtheq_app");
  const userStore = useUserProfileStore();

  if (Object.keys(userStore.account).length === 0 && tokenExists) {
    await userStore.fetchUser();
  }

  const user = userStore.account;

  const roles = to.meta.roles;

  if (roles.includes(user.role?.value) || user.role.value == "admin") {
    console.log("Role Gaurd: User Has Permission");
    return next();
  } else {
    console.log('Role Guard: User has no permission');
    return next("/dashboard");
  }
};
