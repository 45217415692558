import ApiClient from "@/helper/api.helper";

class SecondSide extends ApiClient {
  constructor() {
    super("/api/second-side");
  }

  sendOTP() {
    return axios.get(`${this.url}/send-otp`);
  }

  verifyOTP(data) {
    return axios.patch(`${this.url}/verify-otp`, data);
  }

  getContract(contractId) {
    return axios.get(`${this.url}/contracts/${contractId}`);
  }

  addComment(contractId, data) {
    return axios.patch(`${this.url}/contracts/${contractId}/comments`, data);
  }

  uploadSignature(contractId, data) {
    return axios.post(`${this.url}/contracts/${contractId}/sign`, data);
  }

  approveFinalContract(userId, contractId) {
    return axios.patch(`${this.url}/${userId}/contracts/${contractId}/approve`);
  }

  nafathAuthorize(id) {
    return axios.patch(`${this.url}/nafath/authorize/${id}`);
  }
}

export default new SecondSide();
