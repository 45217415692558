<template>
  <div
    class="position-sticky alarm-status"
    v-if="!isLoading && (checkStatus || !record.has_plan || !records.length)"
  >
    <!-- <div
      class="d-flex align-center ga-2 pa-4 text-white status"
      :class="{
        [record.status]: record.status,
        'can-create': record.status == 'approved' && record.has_plan,
      }"
    >
      <template v-if="record.status == 'approved' && record.has_plan">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <g clip-path="url(#clip0_2011_24534)">
            <path
              d="M13 0.5C6.10729 0.5 0.5 6.10729 0.5 13C0.5 19.8927 6.10729 25.5 13 25.5C19.8927 25.5 25.5 19.8927 25.5 13C25.5 6.10729 19.8927 0.5 13 0.5ZM19.4583 11.45L14.8479 15.976C14.0323 16.776 12.9823 17.175 11.9312 17.175C10.8917 17.175 9.85208 16.7833 9.03958 15.999L7.06146 14.0542C6.65104 13.651 6.64583 12.9917 7.04896 12.5812C7.45104 12.1698 8.1125 12.1646 8.52188 12.5688L10.4937 14.5073C11.3021 15.2885 12.5781 15.2844 13.3906 14.4885L18 9.96354C18.4094 9.55937 19.0667 9.56667 19.4729 9.97708C19.876 10.3875 19.8698 11.0469 19.4583 11.45Z"
              fill="#05AE4B"
            />
          </g>
          <defs>
            <clipPath id="clip0_2011_24534">
              <rect
                width="25"
                height="25"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </template>
      <template v-else>
        <img src="/assets/images/settings/engine-warning-1.svg" alt="" />
      </template>
      <div v-if="record.status == 'pending'" class="d-flex align-center ga-1">
        <p class="text-status">يرجى إستكمال البيانات لتفعيل الحساب</p>

        <router-link
          to="/settings-company-data#company-data"
          class="link-status"
        >
          بيانات الشركة
        </router-link>
        <p class="text-status">والتحقق من</p>
        <router-link to="/settings-company-data#owner-data" class="link-status">
          بيانات مسؤول
        </router-link>
      </div>
      <div v-if="record.status == 'wait_for_approval'">
        <p class="text-status">
          يتم مراجعة بيانات الشركة و سيتم التواصل معكم في حالة الحاجة لمزيد من
          البيانات
        </p>
      </div>
      <div
        v-if="record.status == 'needs_edit'"
        class="d-flex align-center ga-1"
      >
        <p class="text-status">يرجى تعديل البيانات بناءاً على طلب الأدمن</p>
        <router-link
          to="/settings-company-data#company-data"
          class="link-status"
        >
          بيانات الشركة
        </router-link>
      </div>
      <div
        v-if="record.status == 'approved' && !record.has_plan"
        class="d-flex align-center ga-1"
      >
        <p class="text-status">يرجى إختيار خطة الإشتراك لتفعيل الحساب</p>
        <router-link to="/payments-plans" class="link-status">
          خطة الإشتراك
        </router-link>
      </div>
      <div
        v-if="
          record.status == 'approved' && record.has_plan && records.length == 0
        "
        class="d-flex align-center ga-1"
        :class="{
          'can-create': record.status == 'approved' && record.has_plan,
        }"
      >
        <p class="text-status">تم تفعيل حسابك قم بإضافة  عقدك الأول</p>
        <router-link to="/contracts" class="link-status"> العقود </router-link>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useCompanyStore } from "@/stores/companies/profile/profile.store";
import { useContractStore } from "@/stores/companies/contract/contract.store";
export default {
  data() {
    return {};
  },
  async mounted() {
    // await this.getContracts();
  },
  computed: {
    ...mapState(useCompanyStore, ["record", "isLoading"]),
    ...mapState(useContractStore, ["records"]),
    checkStatus() {
      if (
        this.record.status == "pending" ||
        this.record.status == "wait_for_approval" ||
        this.record.status == "needs_edit" ||
        this.record.status == "rejected" ||
        this.record.status == "suspended"
      ) {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(useContractStore, ["getContracts"]),
  },
};
</script>
<style lang="scss" scoped>
.alarm-status {
  top: 0;
  z-index: 99;
}

.status {
  background: #f9efe5;
  &.rejected,
  &.suspended {
    background-color: #f44336;
  }

  &.can-create {
    background: #e2fced;
  }
  .text-status {
    color: #050505;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 9px;
    }
  }
  .link-status {
    color: #050505;
    font-size: 16px;
    font-weight: 600;
    text-decoration-line: underline;
    @media (max-width: 992px) {
      font-size: 10px;
    }
  }
}
</style>
