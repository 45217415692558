<template>
  <div class="">
    <div class="navbar bg-white">
      <v-container>
        <img src="/assets/images/logo.svg" alt="logo" width="120" />
      </v-container>
    </div>
    <v-container>
      <div class="bg-white pa-8 rounded-lg">
        <div class="d-flex align-start ga-2">
          <button @click="$router.go(-1)">
            <span class="mdi mdi-24px mdi-arrow-right"></span>
          </button>
          <div class="section-head mb-6 d-flex align-center ga-2">
            <h2 class="section-head__title">
              {{ $t("PAYMENT.PACKAGES.TITLE") }}
            </h2>
          </div>
        </div>
        <div class="">
          <div v-if="isLoading" class="pa-8 d-flex align-center justify-center">
            <v-progress-circular color="primary" indeterminate />
          </div>
          <div v-else>
            <v-row>
              <v-col
                md="3"
                cols="12"
                v-for="(item, index) in mapList"
                :key="index"
              >
                <div class="plan__item">
                  <div class="">
                    <div class="plan__item__icon mx-auto">
                      <img :src="item.icon" />
                    </div>
                    <div>
                      <p class="plan__item__name">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                  <template v-if="item.can_pay">
                    <button class="plan__item__price">
                      <span>{{ item.price }}</span>
                    </button>
                    <span class="plan__item__count d-block">
                      {{ item.contract_count }}
                    </span>
                    <button
                      class="plan__item__bay ga-1"
                      @click="selectPlan(item)"
                    >
                      اشتراك
                    </button>
                  </template>
                  <template v-else>
                    <span class="plan__item__price d-block">
                      {{ item.contract_count }}
                    </span>
                    <button class="plan__item__bay ga-1" @click="contactUs">
                      تواصل الاّن
                    </button>
                  </template>
                </div>
              </v-col>
            </v-row>

            <div class="section-head my-6 d-flex align-center ga-2">
              <h2 class="section-head__title">مميزات الباقات</h2>
            </div>
            <div class="plan__info">
              <v-row>
                <v-col
                  md="4"
                  cols="6"
                  v-for="(item, index) in plansInfo"
                  :key="index"
                >
                  <div class="d-flex align-center ga-2 plan__info__item">
                    <img
                      class="plan__info__item__icon"
                      src="/assets/images/plans/check-circle.svg"
                      alt="check"
                    />
                    <p class="plan__info__item__text">{{ item }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <div
      class="payment-model"
      :class="{
        'd-none': !dialog,
      }"
    >
      <v-row class="justify-center">
        <v-col md="3" cols="12">
          <div class="w-100 bg-white rounded-lg pa-4">
            <button @click="dialog = false">
              <span class="mdi mdi-24px mdi-close"></span>
            </button>
            <div class="mysr-form pt-8"></div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { usePlansStore } from "@/stores/plans/plans.store";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      plansInfo: [
        "توقيع العقود في أي وقت ومن أي مكان في أسرع وقت ",
        "عقود موثقة بإستخدام تطبيق النفاذ الوطني الموحد ",
        "عقودك مؤمنة ومحفوظة بما يتوافق مع الأنظمة السعودية ",
        "عدد لا نهائي من المفوضين بالتوقيع ",
        "توقيع إلكتروني متوافق مع الأنظمة السعودية",
        "تحديثات لحظية عن طريق نظام الإشعارات والرسائل النصية",
        "أرسل واستلم توقيع جماعي",
        "عدد لا محدود من طلبات التوقيع",
        "إستخدام نظام التعليقات على العقود من كل الأطراف ",
        "سير الموافقات الخاصة بكل عقد على حد ",
      ],
      dialog: false,
    };
  },
  async mounted() {
    await this.getPlans();
  },
  computed: {
    ...mapState(usePlansStore, ["records", "isLoading"]),
    mapList() {
      return this.records.map((item) => {
        return {
          ...item,
          icon: `/assets/images/plans/${item.id}.svg`,
          price: item.can_pay ? `${item.price} ر.س` : "",
          contract_count: item.can_pay
            ? `${item.contract_count} عقد/ سنة`
            : "قم بالتواصل معنا ",
        };
      });
    },
  },
  methods: {
    ...mapActions(usePlansStore, ["getPlans", "contactUs"]),
    selectPlan(plan) {
      this.dialog = true;
      Moyasar.init({
        element: ".mysr-form",
        amount:
          parseFloat(this.records.find((p) => p.id == plan.id).price) * 100,
        currency: "SAR",
        language: "ar",
        description: plan.name,
        publishable_api_key: import.meta.env.VITE_PAYMENT_KEY,
        callback_url: import.meta.env.VITE_URL_APP + "/payments-plans",
        methods: ["creditcard"],
        on_completed: () => {
          return new Promise((resolve, reject) => {
            this.dialog = false;

            if (55) {
              resolve({});
            } else {
              reject();
              // this.dialog = false;
            }
          });
        },
        on_failure: () => {
          // this.dialog = false;
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.plan {
  &__item {
    border-radius: var(--MD, 16px);
    background: #f8fafb;
    text-align: center;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icon {
      display: flex;
      width: 62px;
      height: 62px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      background: #ebf2f2;
      margin-bottom: 8px;
    }
    &__name {
      color: #939393;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
    &__count {
      color: #51a79a;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    &__price {
      color: var(--191919, var(--Black-Primary, #191919));
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 13px;
    }
    &__bay {
      margin-top: 14px;
      width: 171px;
      height: 56px;
      padding: 16px var(--XL, 16px);
      justify-content: center;
      align-items: center;
      gap: var(--Sm, 6px);
      border-radius: var(--XL, 16px);
      border: 1px solid #b5d9d4;
      background: #6db5ab;
      color: var(--Base-White, #fff);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    &:hover {
      background: #51a79a;
      color: #fff;
    }

    &:hover &__name,
    &:hover &__price,
    &:hover &__count {
      color: #fff;
    }

    &:hover &__icon {
      background: #71b7ad;
      img {
        filter: invert(1);
      }
    }
  }
  &__info {
    padding: var(--L, 20px) var(--MD, 16px);
    border-radius: var(--SM, 8px);
    &__item {
      padding: var(--XL, 16px);
      gap: var(--2XL, 20px);
      border-radius: var(--XL, 16px);
      background: #fafafa;

      &__icon {
        width: 20px;
        height: 20px;
      }
      &__text {
        color: var(--Black-Primary, #191919);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
      }
    }
  }
}
.payment-model {
  position: fixed;
  inset: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
